import React, { useEffect, useState } from "react";
import { ReactComponent as ProDesign } from "../../assets/img/pro_design.svg";
import { ReactComponent as SquareTick } from "../../assets/img/square_tick.svg";
import "./Plan.scss";

export default function Plan(props) {
  const [subscriptionPackage] = useState(props.subscriptionPackage);
  const [features] = useState(props.features);
  const [theme] = useState(props.theme);
  const [currentPlan] = useState(props.currentPlan ? props.currentPlan : 'FREE');
  const [activeActivites] = useState(props.activeActivites ?? []);
  const [selectedActivity, setSelectedActivity] = useState(null);

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search)
    const activityId = queryParameters.get("activity_id")

    if (activeActivites.length > 0) {
      const activeActivity = (activeActivites.filter((aa) => aa.activity_id === activityId).length > 0)
        ? activeActivites.filter((aa) => aa.activity_id === activityId)[0]
        : activeActivites.filter((aa) => !aa.is_disabled)[0];

      setSelectedActivity(activeActivity);
    }
  }, [activeActivites]);

  function getDecimalPart(num) {
    return Number(num % 100);
  }

  function BlankPlaceholder() {
    return (<div className="blank-box"></div>)
  }

  function ActivePlaceholder() {
    return (<div className="active-box">Dabartinis planas</div>)
  }

  function ActionButton() {
    return (
      <button 
        className="btn btn-outline-primary btn-subscribe" 
        onClick={() => props.onSelectPlan(subscriptionPackage, selectedActivity)}
        disabled={(subscriptionPackage.key === "PRO_1_OBJECT" && (!selectedActivity || selectedActivity.is_disabled)) ? 'diabled' : ''}
      >
        { subscriptionPackage.button_text }
      </button>
    )
  }

  function ActionHolder() {
    switch (subscriptionPackage.key) {
      case 'FREE':
        return (currentPlan === subscriptionPackage.key) ? <ActivePlaceholder /> : <BlankPlaceholder />;
      case 'PRO_1_OBJECT':
        return <ActionButton />
      case 'PRO_3_MONTHS':
      case 'PRO_12_MONTHS':
        return (['FREE', 'PRO_1_OBJECT'].includes(currentPlan))
          ? <ActionButton /> 
          : (currentPlan === subscriptionPackage.key ? <ActivePlaceholder /> : <BlankPlaceholder />);
      default:
        return <></>
    }
  }

  return (
    <>
      {
        subscriptionPackage && (
          <div className={`Plan ${theme ? theme : ''}`}>
            <h4>{ subscriptionPackage.title }</h4>
            <div className="object-counter">
              <div>Ataskaitų kiekis:</div>
              <h4>{ subscriptionPackage.objects_count > 0 ? subscriptionPackage.objects_count : "neribojamas" }</h4>
            </div>
            <div className="price-block">
              { Math.floor(subscriptionPackage.price_monthly / 100) }
              <sup>
                { getDecimalPart(subscriptionPackage.price_monthly) > 0 ? getDecimalPart(subscriptionPackage.price_monthly) : '' }
              </sup>
              <sub>
                { subscriptionPackage.currency_symbol }{ subscriptionPackage.type === 'monthly' ? "/mėn." : "" }
              </sub>
            </div>
            { subscriptionPackage.discounted && 
              <>
                <div className="discounted-text mt-3">
                  <span style={{textDecoration: 'line-through'}}>
                    {`${(subscriptionPackage.price_monthly_original/100).toFixed(2)}${subscriptionPackage.currency_symbol}`}
                  </span>
                  <span className="discount-box ml-1">{ subscriptionPackage.discount_percentage }%</span>
                  { subscriptionPackage.discount_percentage_original !== 0 ?
                    <>
                      <span className="ml-1">+</span>
                      <span className="discount-box ml-1">{ subscriptionPackage.discount_percentage_original }%</span>
                    </> :
                    <span className="ml-1">nuolaida</span>
                  }
                </div>
                <div className={`total-text ${!subscriptionPackage.discounted ? 'mt-md-5' : ''}`}>{`Mokate ${subscriptionPackage.currency_symbol}${(subscriptionPackage.price/100).toFixed(2)} už`} <b>{`${subscriptionPackage.months_count} ${subscriptionPackage.months_count === 12 ? 'mėnesių' : 'mėnesius'}`}</b></div>
              </>
            }
            { !subscriptionPackage.discounted && 
              <div className="no-discount-space"></div>
            }
            { !subscriptionPackage.discounted && !subscriptionPackage.activity_dropdown && 
              <div className="no-discount-dropdown-space"></div>
            }

            { subscriptionPackage.activity_dropdown &&
              <>
              <div className="object-dropdown">
                <div className="dropdown">
                  <button className="addressButton btn dropdown-toggle my-2" type="button" id="addressButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="dropdown-toggle-title">{ selectedActivity?.object_name }</div>
                      <div className="dropdown-toggle-arrow"></div>
                    </div>
                  </button>
                  <div className="dropdown-menu" aria-labelledby="addressButton">
                    { activeActivites.length > 0 && activeActivites.map((activeActivity) => (
                      <div key={activeActivity.activity_id} className={`dropdown-item ${activeActivity.is_disabled ? 'disabled' : ''}`} onClick={() => setSelectedActivity(activeActivity)}>
                        { activeActivity.object_name }
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              </>
            }

            <ActionHolder />

            {
              <>
                <div className="mt-2 text-muted">
                  {(subscriptionPackage.key === "PRO_1_OBJECT" && (
                    <span>Galioja 7 d.</span>
                  )) || (
                    <span>&nbsp;</span>
                  )}
                </div>
              </>
            }

            <div className="horizontalLine"><hr/></div>
            <div>
              <ul className="feature-list">
                {
                  features && features.map(feature => (
                    <li key={feature.key}>
                      <SquareTick
                        className={ `tick ${(subscriptionPackage.active_features.includes(feature.key) || subscriptionPackage.active_features === "*" ) ? 'tick-active' : ''}` } 
                      />
                      { feature.title }
                      { 
                        !feature.pro
                        ? ''
                        : <span className="pro-text"><ProDesign/></span>
                      }
                    </li>
                  ))
                }
              </ul>
            </div>
          </div>
        )
      }
    </>
  );
}
