import React, {useEffect} from "react";
import {useState} from "react";
import Axios from "axios";
import ImageGift from "../../assets/img/gift.svg";

export default function SubscriptionPayment(props) {
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);
  const [hasTermsError, setHasTermsError] = useState(false);
  const [discountCode, setDiscountCode] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (isTermsAccepted) setHasTermsError(false)
  }, [isTermsAccepted]);
  
  const handlePlanPayment = () => {
    if (!isTermsAccepted) {
      setHasTermsError(true);
      return;
    }

    window.sys.track(`pricing_${props.plan.key.toLowerCase()}_pay.click`, props.activityId);

    let redirectUrl = process.env.REACT_APP_SITE_URL + `opay/payment?plan_id=${props.plan.key}`;

    if (props.plan.activity_dropdown) {
      redirectUrl += `&activity_id=${props.activityId}`;
    }

    if (props.plan.discount_code) {
      redirectUrl += `&discount=${props.plan.discount_code.code}`;
    }

    window.location = redirectUrl;
  }

  return (
    <React.Fragment>
      <div className="SubscriptionPayment container mt-5" style={{maxWidth: "860px"}}>
        <h2>Jūsų užsakymas</h2>
        <p className="mt-3">Mokėjimo būdo pasirinkimas</p>

        <div className="row card p-3 p-sm-5 mt-5">
          <table className="table">
            <thead>
            <tr className="summary-box-header">
              <th className="text-left border-top-0">Pavadinimas</th>
              <th className="border-top-0">Kiekis</th>
              <th className="border-top-0" style={{width: "110px"}}>Kaina</th>
            </tr>
            </thead>
            <tbody>
            <tr className="summary-box-item">
              <td className="text-left">Ataskaitų prenumerata</td>
              <td>{props.plan.title}</td>
              <td className="text-right">
                {props.plan.discounted && <>
                  <span className="text-danger"
                        style={{textDecoration: "line-through"}}>{props.plan.price_monthly_original / 100}&nbsp;€</span>
                  {" "}
                </>}
                {props.plan.price_monthly / 100}&nbsp;€
              </td>
            </tr>
            {props.plan.discount_code && <>
              <tr className="summary-box-item font-weight-bold">
                <td className="text-left" colSpan={2}>Viso</td>
                <td className="text-right">{props.plan.price / 100}&nbsp;€</td>
              </tr>
              <tr className="summary-box-item text-success">
                <td className="text-left" colSpan={2}>Nuolaidos kodas
                  (<b>{props.plan.discount_code.code.toUpperCase()}</b>)
                </td>
                {props.plan.discount_code.type === 'pct' ?
                  <td className="text-right">-{props.plan.discount_code.value}&nbsp;%</td>
                  :
                  <td className="text-right">-{props.plan.discount_code.value / 100}&nbsp;€</td>
                }
              </tr>
            </>
            }
            <tr className="summary-box-total">
              <td className="text-left" colSpan={2}>Mokėtina suma</td>
              <td className="text-right">{props.plan.final_price / 100}&nbsp;€</td>
            </tr>
            </tbody>
          </table>
        </div>

        <div className="row card p-3 p-sm-5 mt-5">
          <div className="text-left">
            <h6>
              <img src={ImageGift} alt="Gift"/>
              <span className="align-bottom ml-1">Turite nuolaidos kodą?</span>
            </h6>
            <p className="payment-box-subtitle">Pridėkite kodą ir gaukite nuolaidą</p>
          </div>
          <div className="border-bottom"></div>

          <form className="form-inline mt-3">
            <div className="form-group mb-0 mr-2 mt-2 mt-sm-0">
              <input
                type="text"
                className="form-control"
                id="inputCode"
                placeholder="Įveskite kodą"
                onChange={(e) => setDiscountCode(e.target.value)}
                value={discountCode}
              />
            </div>
            <button type="submit" className="btn btn-primary mt-2 mt-sm-0"
                    disabled={discountCode === ""}
                    onClick={(e) => {
                      e.preventDefault();
                      props.handleDiscount(discountCode);
                      setDiscountCode("");
                    }}
            >Įvesti
            </button>
          </form>
        </div>

        <div className="row p-3 p-sm-5">
          <div className="col-12 text-center">
            <label className="field-container">
              <input type="checkbox" onChange={(e) => setIsTermsAccepted(e.target.checked)}/>
              <span>
                Sutinku su UNTU <a href="/terms-and-conditions" target="_blanks">bendrosiomis taisyklėmis</a>.
              </span>
              {hasTermsError &&
                <p className="text-danger text-left" style={{marginLeft: '17px'}}>Prieš spaudami “Sumokėti“, pažymėkite
                  , kad sutinkate su taisyklėmis.</p>}
            </label>
          </div>

          <div className="col-12 text-center mt-3">
            <button className="btn btn-lg btn-primary"
                    disabled={!isTermsAccepted}
                    onClick={() => handlePlanPayment()}>Sumokėti
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
