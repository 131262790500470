import React from "react";
import { useState } from "react";
import Plan from "./Plan";
import ImageAlarm from "../../assets/img/alarm.png";
import ImageBinocular from "../../assets/img/binocular.png";
import ImageGraph from "../../assets/img/graph.png";
import ImageDocument from "../../assets/img/document.png";
import "./Pricing.scss";
import bellSrc from "../../assets/img/bell-white.svg";


export default function Plans(props) {
  const [plans] = useState(props.plans ?? []);

  return (
    <React.Fragment>
      <h2 className="mt-5">KAINOS</h2>

      <h3 className="mt-4">Būsto ataskaitos</h3>

      {process.env.REACT_APP_SUBSCRIPTION_PLAN_TEASER_TEXT && (
      <div className="bg-dark p-2"><img src={bellSrc} alt="" className="mr-2" />
        <span className="align-middle">{process.env.REACT_APP_SUBSCRIPTION_PLAN_TEASER_TEXT}</span>
      </div>
      )}

      <div className="container">

      { (plans.length && props.features.length) ?
        <div className="row mt-5 px-2 px-sm-0">
          <div className="col-12 col-md-6 package-group">
            <div className="group-title pt-0 pt-md-3 d-none d-md-block">&nbsp;</div>
              <div className="row">
                  <div className="col-12 col-xl-6 d-flex justify-content-center">
                      <Plan
                        subscriptionPackage={plans.find(sub => sub.key === 'FREE')}
                        {...props}
                      ></Plan>
                  </div>
                  <div className="col-12 col-xl-6 d-flex justify-content-center">
                      <Plan
                        subscriptionPackage={plans.find(sub => sub.key === 'PRO_1_OBJECT')}
                        onSelectPlan={props.onSelectPlan}
                        activeActivites={props.activeActivites}
                        {...props}
                      ></Plan>
                  </div>
              </div>
          </div>
          <div className="col-12 col-md-6 package-group monthly-packages">
            <div className="group-title">Mėnesinės prenumeratos</div>
            <div className="row no-gutters">
              <div className="col-12 col-xl-6 pr-xl-1 d-flex justify-content-center">
                <Plan
                  subscriptionPackage={plans.find(sub => sub.key === 'PRO_3_MONTHS')}
                  theme="dark"
                  onSelectPlan={props.onSelectPlan}
                  {...props}
                ></Plan>
              </div>
              <div className="col-12 col-xl-6 pl-xl-1 d-flex justify-content-center">
                <Plan
                  subscriptionPackage={plans.find(sub => sub.key === 'PRO_12_MONTHS')}
                  onSelectPlan={props.onSelectPlan}
                  {...props}
                ></Plan>
              </div>
            </div>
          </div>
        </div> :
        ''
      }

      <h3 className="sell-title">Ruošiatės pirkti arba parduoti būstą?</h3>

      <p className="mb-5">Sužinokite pirmieji, kokie sandoriai įvyko jūsų name arba kaimynystėje.</p>

      <div className="row d-flex justify-content-center">
        <div className="col-12 col-md-10">
          <div className="row justify-content-center">
            <div className="col-10 col-md-3 content-block">
              <div className="align-self-center">
                <img src={ImageBinocular} alt="Binocular"/>
              </div>
              <div className="content">Vienu metu stebėkite kainų pokyčius neribotam objektų kiekiui.</div>
            </div>
            <div className="col-10 col-md-3 content-block">
              <div className="align-self-center">
                <img src={ImageGraph} alt="Graph"/>
              </div>
              <div className="content">
                Profesionalios būsto ataskaitos dėka, jūsų būsto pirkimo ar pardavimo sprendimai taps dar 
                geriau pagrįsti ir informuoti.
              </div>
            </div>
            <div className="col-10 col-md-3 content-block">
              <div className="align-self-center">
                <img src={ImageAlarm} alt="Alarm"/>
              </div>
              <div className="content">Kartą per mėnesį informuosime apie naujausius sandorius jūsų stebimų objektų kaimynystėje.</div>
            </div>
            <div className="col-10 col-md-3 content-block">
              <div className="align-self-center">
                <img src={ImageDocument} alt="Document"/>
              </div>
              <div className="content">
                Galimybė atsisiųsti ataskaitą PDF formatu.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-5 justify-content-center">
        <div className="col-8">
          <b>Jeigu turite papildomų klausimų - <a href="/contact-us" onClick={() => {
              window.sys.track('pricing_contact_us.click');
          }}>susiekite su mumis.</a></b>
        </div>
      </div>
    </div>
    </React.Fragment>
  )
}
