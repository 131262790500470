import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: process.env.REACT_APP_LOCALE || 'en',
    fallbackLng: process.env.REACT_APP_FALLBACK_LOCALE || "en",
    backend: {
      loadPath: `${process.env.REACT_APP_SITE_URL}locales/{{lng}}/{{ns}}.json?${window.appProperties.translateHash || (new Date()).getTime()}`
    },
    debug: process.env.REACT_APP_I18N_DEBUG || false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
      interpolation: {
        format: function (value, format, lng) {
          if (format === 'uppercase') return value.toUpperCase();
          return value;
        }
      }
    },
    react: {
      useSuspense: true,
      transKeepBasicHtmlNodesFor: ['a', 'b', 'br'],
    },
  });

export default i18n;
