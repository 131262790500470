import Axios from "axios";
import React, { useEffect, useMemo } from "react";
import { useState } from "react";
import Plans from "./Plans";
import Payment from "./Payment";
import { ReactComponent as ShieldTick } from "../../assets/img/shield-tick.svg";
import ImageLuminor from "../../assets/img/Luminor.png";
import ImageSEB from "../../assets/img/SEB.png";
import ImageSwedbank from "../../assets/img/Swedbank.png";
import ImageRevolut from "../../assets/img/Revolut.png";
import ImageSiauliu from "../../assets/img/Siauliu.png";
import "./Pricing.scss";

export default function Pricing(props) {
  const [features, setFeatures] = useState([]);
  const [plans, setPlans] = useState([]);
  const [planKey, setPlanKey] = useState(null);
  const [selectedActivity, setSelectedActivity] = useState(null);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const plan = useMemo(() => plans.find(p => p.key === planKey) || null, [planKey, plans]);

  useEffect(() => {
    fetchFeatures();
    fetchPricingOptions();
  }, []);

  function fetchFeatures() {
    Axios.get(`${process.env.REACT_APP_API_URL}untu/plan-features`).then((response) => {
      setFeatures(response.data.data);
    });
  }

  async function fetchPricingOptions(discount) {
    const params = {};
    if (discount) {
      params.discount = discount.trim();
    }
    return await Axios.get(`${process.env.REACT_APP_API_URL}untu/plans`, {
      params,
    }).then((response) => {
      setPlans(response.data.data);
      return response.data.data;
    }).catch((error) => {
      throw error.response.data.message;
    });
  }

  const handleSelectPlan = (plan, selectedActivity = null) => {
    setPlanKey(plan.key);
    setSelectedActivity(selectedActivity);
    window.sys.track(`pricing_${plan.key.toLowerCase()}.click`, selectedActivity?.activity_id)
  }

  async function handleDiscount(discount) {
    try {
      const response = await fetchPricingOptions(discount);
      if (response.length > 0) {
        setError(null);
        setSuccess("Nuolaidos kodas sėkmingai pritaikytas");
        window.sys.track(`pricing_discount.added`, selectedActivity?.activity_id);
        setTimeout(() => setSuccess(null), 5000);
      }
    } catch (error) {
      setError(error);
      setTimeout(() => setError(null), 5000);
    }
  }

  return (
    <React.Fragment>
      {(success || error) && <div className={`alert ${ success ? "alert-success" : "alert-danger" } position-fixed w-100 p-4 text-center`} style={{top: "0", left: "0", right: "0", zIndex: "10000"}}>{success || error}</div>}

      <div id="content" className="Pricing">
        { planKey === null && 
          plans.length > 0 &&
          features.length > 0 &&
          <Plans 
            plans={plans} 
            features={features} 
            onSelectPlan={handleSelectPlan}
            {...props}
          />
        }
        { plan !== null && 
          <Payment
            plan={plan}
            activityId={selectedActivity.activity_id}
            handleDiscount={handleDiscount}
          /> 
        }

        <div className="footer-block mt-5">
            <div>
              Saugus atsiskaitymas <ShieldTick/>
            </div>
            <div className="logos">
              <img src={ImageSwedbank} alt="Swedbank"/>
              <img src={ImageSEB} alt="SEB"/>
              <img src={ImageLuminor} alt="Luminor"/>
              <img src={ImageRevolut} alt="Revolut"/>
              <img src={ImageSiauliu} alt="Siauliu"/>
            </div>
        </div>
      </div>
    </React.Fragment>
  )
}
