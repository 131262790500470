import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "cookiePopup",
  initialState: {
    isVisible: false,
  },
  reducers: {
    showConsent: (state) => {
      state.isVisible = true;
    },
    hideConsent: (state) => {
      state.isVisible = false;
    },
  },
});

export const { showConsent, hideConsent } = slice.actions;

export const { isVisible } = (state) => state.cookiePopup;

export default slice.reducer;
